export const buildAppsflyerURL = (url, client) => {
    const appsflyerStr = client?.appsflyer || '{}';
    let appsflyerJson;

    try {
        appsflyerJson = JSON.parse(appsflyerStr);
    } catch (error) {
        appsflyerJson = {};
    }

    const mediaSource = appsflyerJson.media_source || '';
    const campaign = appsflyerJson.campaign || '';
    const afAdset = appsflyerJson.af_adset || '';

    const isSDKParam = 'isMobileSDK=true';
    const queryParams = [];

    if (mediaSource) {
        queryParams.push(`media_source=${mediaSource}`);
    }

    if (campaign) {
        queryParams.push(`campaign=${campaign}`);
    }

    if (afAdset) {
        queryParams.push(`af_adset=${afAdset}`);
    }

    const queryString = queryParams.join('&');

    return `${url}?${isSDKParam}${queryString ? `&${queryString}` : ''}`;
};

export default {};
