import PropTypes from 'prop-types';
import { useScript } from '@Green-Dot-Corporation/web-app-utils-hooks';

const GoogleTagManager = ({ url }) => {
    useScript(url);

    return <div style={{ display: 'none' }} data-gtm={url} />;
};

GoogleTagManager.propTypes = {
    url: PropTypes.string.isRequired,
};

export default GoogleTagManager;
