import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingFlow } from '@Green-Dot-Corporation/eureka-ui-loading';
import { RouterUtilsContext } from '@Green-Dot-Corporation/eureka-lib-router-utils';
import loadable from '@loadable/component';
import { getFeatureLoader } from '../../services/featureLoaderService';
import { getAppLayoutProps } from '../../services/appService';

export default class FeatureLoader extends PureComponent {
    static propTypes = {
        appConfig: PropTypes.object,
        feature: PropTypes.object,
        isHidden: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            currentFeature: props.feature,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { currentFeature } = state;
        const { feature } = props;

        if (feature !== currentFeature) {
            return {
                isLoading: true,
                module: undefined,
                routerContext: { routePath: feature.route },
                hasError: false,
                currentFeature: feature,
            };
        }

        // react shows warning if we return undefined
        return null; // eslint-disable-line no-null/no-null
    }

    state = {
        isLoading: true,
    };

    render() {
        const {
            module: Module,
            isLoading,
            hasError,
            routerContext,
        } = this.state;
        const { appConfig, isHidden, feature } = this.props;

        if (isHidden) {
            return undefined;
        }

        const moduleProps = {
            ...getAppLayoutProps(),
            feature,
            appConfig,
            moduleRoutePath: feature.route,
        };

        return (
            <LoadingFlow
                isLoading={isLoading}
                hasError={hasError}
                // TODO: translate these error messages
                errorStateLocale={{ title: 'Error Loading Feature' }}
                loadingStateLocale={{ title: 'Loading Feature' }}
            >
                {Module && (
                    <RouterUtilsContext.Provider value={routerContext}>
                        <Module {...moduleProps} />
                    </RouterUtilsContext.Provider>
                )}
            </LoadingFlow>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const { isLoading } = this.state;
        const { isLoading: prevIsLoading } = prevState;

        if (isLoading === true && isLoading !== prevIsLoading) {
            this.loadFeatureModule();
        }
    }

    componentDidMount() {
        this.loadFeatureModule();
    }

    async loadFeatureModule() {
        const { feature = {}, isHidden } = this.props;

        try {
            const featureRenderer = await getFeatureLoader(feature);

            if (isHidden) {
                this.setState({ isLoading: false });
            } else {
                const module = loadable(featureRenderer);
                this.setState({
                    module,
                    isLoading: false,
                    routerContext: { routePath: feature.route },
                });
            }
        } catch (e) {
            this.setState({ hasError: true, isLoading: false });
        }
    }
}
