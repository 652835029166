import { useEffect } from 'react';
import { kickstartForter } from '../../services/forter';

const ForterFraudDetection = ({ appConfig = {} }) => {
    useEffect(() => {
        const { featureConfig = {} } = appConfig;
        const { forter = {} } = featureConfig;
        const { siteId } = forter;

        if (siteId) {
            kickstartForter(siteId);
        }
    }, [appConfig]);

    return undefined;
};

export default ForterFraudDetection;
