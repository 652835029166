import { Component } from 'react';
import { Callout, VARIANTS } from '@Green-Dot-Corporation/eureka-ui-callouts';
import { setAppLayoutProps } from '../../services/appService';

export default class ErrorBoundary extends Component {
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    constructor(props) {
        super(props);
        const { children, ...rest } = this.props;
        setAppLayoutProps({ ...rest });
    }

    state = {};

    render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            // You can render any custom fallback UI
            return (
                <Callout
                    headingLvl={1}
                    title="Something went wrong"
                    variant={VARIANTS.ERROR}
                />
            );
        }

        return children;
    }
}
