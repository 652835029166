export const BRANDING_CACHE_KEY = 'BLUESKY_APP_BRANDING';
export const IS_APP_CONFIG_AUTHENTICATED_KEY = 'IS_AUTHENTICATED_APP_CONFIG';
export const APP_CONFIG_STAGE_PARAM = 'Stage';
export const APP_CONFIG_STAGE_PRE_LOGIN = 'prelogin';
export const APP_CONFIG_STAGE_POST_LOGIN = 'postlogin';

export const REQUEST_ID_HEADER = 'Request-ID';

export const DO_NOT_STORE_COOKIE_URLS = {
    '/sdk-landing': true,
};

export default {};
