import { get, head } from '@Green-Dot-Corporation/eureka-lib-fetch-utils';
import { generateCSSVars } from '@Green-Dot-Corporation/eureka-lib-theme-utils';
import { cacheBranding, getCachedBranding } from './storageService';
import { buildTokenURL } from '../utils/urlUtils';

const brandingTokensStr = getCachedBranding();
let cachedBrandingConfig;

if (brandingTokensStr) {
    try {
        cachedBrandingConfig = JSON.parse(brandingTokensStr);
    } catch (e) {
        console.log('error parsing cached bradning', e);
    }
}

const BUILT_IN_FONTS = ['Bogle', 'Averta', 'Poppins'];

const FONT_PROPS = {
    'core.typography.headings.font.family': [
        'headingFontRegular',
        'headingFontItalics',
        'headingFontBold',
        'headingFontBoldItalics',
    ],
    'core.typography.font.family': [
        'fontRegular',
        'fontItalics',
        'fontBold',
        'fontBoldItalics',
    ],
};

const FONT_ATTRIBUTES_MAP = [
    ['normal', 'normal'],
    ['italic', 'normal'],
    ['normal', 'bold'],
    ['italic', 'bold'],
];

const getBrandingTokens = async (config) => {
    const themeFile = config.branding.isInDarkMode
        ? 'web-dark.json'
        : 'web.json';
    const tokensResponse = await get(buildTokenURL(config, themeFile), {
        shouldIncludeResponseAttributes: true,
        credentials: 'same-origin',
    });

    return tokensResponse;
};

const getBrandingTokensLastModified = async (config) => {
    const themeFile = config.branding.isInDarkMode
        ? 'web-dark.json'
        : 'web.json';
    const response = await head(buildTokenURL(config, themeFile), {
        credentials: 'same-origin',
    });

    return response.headers;
};

const getFontConfig = (brandingConfig, brandingTokens) => {
    const fonts = [];
    Object.getOwnPropertyNames(FONT_PROPS).forEach((fontNameProp) => {
        const fontName = brandingTokens[fontNameProp];
        const fontTypeProps = FONT_PROPS[fontNameProp];
        const [fontRegular, fontItalics, fontBold, fontBoldItalics] =
            fontTypeProps.map((prop) => brandingConfig[prop]);

        if (fontName && BUILT_IN_FONTS.indexOf(fontName) === -1) {
            const fontCSSVarName = `eureka-${fontNameProp.replaceAll(
                '.',
                '-',
            )}`;
            fonts.push({
                name: fontName,
                paths: [fontRegular, fontItalics, fontBold, fontBoldItalics],
                cssVarsName: fontCSSVarName,
            });
        }
    });

    return fonts;
};

const loadFonts = async (fontConfigs, config) => {
    const { resourcePath, branding } = config;
    const { mediaPath } = branding;
    fontConfigs.forEach(({ name, paths }) => {
        paths.forEach((path, i) => {
            if (path) {
                const fontUrl = `${resourcePath}${mediaPath}${path}`;
                const fontFace = new FontFace(name, `url(${fontUrl})`);

                const [style, weight] = FONT_ATTRIBUTES_MAP[i];

                fontFace.weight = weight;
                fontFace.style = style;
                fontFace.display = 'fallback';
                document.fonts.add(fontFace);
                fontFace.load();
            }
        });
    });

    await document.fonts.ready;
};

const addFontCSSVars = (fonts, cssVariableParam, fontsToLoad) => {
    let cssVariables = cssVariableParam;

    fonts.forEach((fontConfig) => {
        const { name, paths, cssVarsName } = fontConfig;

        const index = cssVariables.lastIndexOf('}');
        cssVariables = `${cssVariables.substring(
            0,
            index,
        )}--${cssVarsName}: '${name}';\n${cssVariables.substring(index)}`;

        fontsToLoad.push({
            name,
            paths,
        });
    });

    return cssVariables;
};

const loadBranding = async (brandingTokens, appConfig) => {
    const fontsToLoad = [];

    let cssVariables = generateCSSVars(brandingTokens);
    const fonts = getFontConfig(appConfig.branding, brandingTokens);

    cssVariables = addFontCSSVars(fonts, cssVariables, fontsToLoad);

    await loadFonts(fontsToLoad, appConfig);

    const stylesheet = document.createElement('style');
    stylesheet.appendChild(document.createTextNode(cssVariables));

    document.head.appendChild(stylesheet);
};

const updateCachedBranding = async (config) => {
    if (cachedBrandingConfig) {
        const headers = await getBrandingTokensLastModified(config);
        const cachedLastModified = cachedBrandingConfig.lastModified;
        const currentLastModified = (headers ?? {})['last-modified'];
        const currentIsInDarkMode = config.branding?.isInDarkMode;
        const cachedIsInDarkMode =
            cachedBrandingConfig.appConfig?.branding?.isInDarkMode;

        if (
            cachedLastModified !== currentLastModified ||
            currentIsInDarkMode !== cachedIsInDarkMode
        ) {
            const brandingTokensResponse = await getBrandingTokens(config);
            const { data: brandingTokens, headers: tokenHeaders } =
                brandingTokensResponse;
            const lastModified = tokenHeaders['last-modified'];

            cachedBrandingConfig = {
                tokens: brandingTokens,
                appConfig: config,
                lastModified,
            };

            cacheBranding(cachedBrandingConfig);
        }
    }
};

export const setupBranding = async (config) => {
    const { branding } = config ?? {};

    if (!branding) {
        return;
    }

    if (cachedBrandingConfig) {
        const currentIsInDarkMode = config.branding.isInDarkMode;
        const cachedIsInDarkMode =
            cachedBrandingConfig.appConfig.branding.isInDarkMode;

        await updateCachedBranding(config);
        const { tokens: brandingTokens } = cachedBrandingConfig;

        if (currentIsInDarkMode !== cachedIsInDarkMode) {
            await loadBranding(brandingTokens, config);
        }

        return;
    }

    const brandingTokensResponse = await getBrandingTokens(config);
    const { data: brandingTokens, headers } = brandingTokensResponse;
    const lastModified = headers['last-modified'];

    cacheBranding({
        tokens: brandingTokens,
        appConfig: config,
        lastModified,
    });

    await loadBranding(brandingTokens, config);
};

export const setupCachedBranding = async () => {
    if (cachedBrandingConfig) {
        const { tokens: brandingTokens, appConfig } = cachedBrandingConfig;
        await loadBranding(brandingTokens, appConfig);
    }
};

export default {};
