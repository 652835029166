export const eventHandler = (eventName, options) => {
    window.dataLayer = window.dataLayer ?? [];
    const {
        eventOptions,
        eventType,
        pageName,
        target,
        accountIdentifier,
        productCode,
        ...rest
    } = options ?? {};
    const { eventName: evtName, ...restOptions } = eventOptions ?? {};
    const overvideOptions = {};

    if (accountIdentifier && productCode) {
        overvideOptions.gd_userID = accountIdentifier;
        overvideOptions.gd_cardPartner = productCode;
    }

    window.dataLayer.push({
        ...rest,
        ...restOptions,
        ...overvideOptions,
        event: eventName,
    });

    return eventName;
};

export default {};
