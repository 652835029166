import { LOG_LEVEL } from '../../const/loggerConst';
import { sendLogToGateway } from './LoggerService';

const { INFO, DEBUG, WARN, ERROR } = LOG_LEVEL;
class Logger {
    config;

    constructor(config) {
        this.config = config;
    }

    log(level, message) {
        if (!Object.values(LOG_LEVEL).includes(level)) return;
        const request = { message, level, ...this.config };
        sendLogToGateway(request);
    }

    info(message) {
        this.log(INFO, message);
    }

    debug(message) {
        this.log(DEBUG, message);
    }

    warn(message) {
        this.log(WARN, message);
    }

    error(message) {
        this.log(ERROR, message);
    }
}
export default Logger;
