import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './styles/header.scss';

export default class Header extends PureComponent {
    static defaultProps = {
        appConfig: {},
    };

    static propTypes = {
        appConfig: PropTypes.object,
    };

    render() {
        const modCls = this.getModCls();

        return (
            <header className={cx(this.baseCls, modCls)}>
                {this.renderLogo()}
            </header>
        );
    }

    renderLogo = () => {
        const logoPath = this.getLogoPath();
        const brandName = this.getBrandName();

        return (
            <div className={`${this.baseCls}__logo-container`}>
                <img
                    className={`${this.baseCls}__logo`}
                    src={logoPath}
                    alt={brandName ? `${brandName} logo` : 'logo'}
                />
            </div>
        );
    };

    getLogoPath = () => {
        const { appConfig } = this.props;
        const { resourcePath = '', branding = {} } = appConfig;
        const { mediaPath = '' } = branding;

        return `${resourcePath}${mediaPath}/logo.svg`;
    };

    getBrandName = () => {
        const { appConfig } = this.props;
        const { branding = {} } = appConfig;
        const { brandName = '' } = branding;

        return brandName;
    };

    getModCls = () => {
        const { appConfig } = this.props;
        // TODO: validate this method to determine authenticated flow
        const { user } = appConfig;

        return {
            [`${this.baseCls}--post-login`]: !!user,
        };
    };

    baseCls = 'blue-sky-header';
}
