import { get } from '@Green-Dot-Corporation/eureka-lib-fetch-utils';
import { v4 as uuidv4 } from 'uuid';
import { getFeatureModule } from './featureLoaderService';
import { shouldGetAuthenticatedAppConfig } from './storageService';
import {
    APP_CONFIG_STAGE_PARAM,
    APP_CONFIG_STAGE_POST_LOGIN,
    APP_CONFIG_STAGE_PRE_LOGIN,
    REQUEST_ID_HEADER,
} from '../const/appConst';

let appLayoutProps = {};
let bootstrapModule;
export const getAppConfig = async () => {
    const options = {
        queryParams: {
            [APP_CONFIG_STAGE_PARAM]: shouldGetAuthenticatedAppConfig()
                ? APP_CONFIG_STAGE_POST_LOGIN
                : APP_CONFIG_STAGE_PRE_LOGIN,
        },
        headers: {
            [REQUEST_ID_HEADER]: uuidv4(),
        },
    };

    const config = await get('/appconfig/v1/Config', options);

    return config;
};

export const isAuthenticated = () => {
    if (bootstrapModule) {
        return bootstrapModule.isAuthenticated();
    }

    return shouldGetAuthenticatedAppConfig();
};

export const loadConfigFromBootstrap = async (appConfig) => {
    const bootstrapFeatureDef = appConfig?.featureConfig?.bootstrap;

    if (!bootstrapFeatureDef) {
        bootstrapModule = undefined;

        return appConfig;
    }
    const module = await getFeatureModule(bootstrapFeatureDef);

    bootstrapModule = module;

    const newAppConfig = await bootstrapModule.getAppConfig(appConfig);

    return newAppConfig;
};

/**
 * props set by layout to be passed to child modules
 */
export const setAppLayoutProps = (layoutProps) => {
    appLayoutProps = layoutProps ?? {};
};

export const getAppLayoutProps = () => appLayoutProps;

export default {};
