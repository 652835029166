import { PureComponent } from 'react';
import { LoadingFlow } from '@Green-Dot-Corporation/eureka-ui-loading';
import { exitWebview, redeemToken } from '../services/sdkLandingService';

export default class SDKLanding extends PureComponent {
    state = {
        isLoading: true,
        hasError: false,
        errorInfo: undefined,
    };

    render() {
        const { isLoading, hasError, errorInfo } = this.state;

        if (hasError) {
            throw new Error(errorInfo);
        }

        return <LoadingFlow isLoading={isLoading} />;
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        try {
            if (token) {
                try {
                    const redirectUrl = await redeemToken(token);
                    window.location.href = redirectUrl;
                } catch (error) {
                    const { errorCode } = error ?? {};
                    console.log(errorCode);
                    exitWebview();
                }
            } else {
                exitWebview();
            }
        } catch (error) {
            const updatedState = {
                isLoading: false,
                hasError: true,
                errorInfo: error,
            };
            this.setState(updatedState);
        }
    };
}
