import { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    GTM_SITE_KEY,
    GTM_SCRIPT,
    GTM_NO_SCRIPT_SRC,
} from '../../const/googleGtmConst';
import { eventHandler } from './services/GoogleGtmAnalyticsService';

const replaceText = (scriptText, sitekey) => {
    return scriptText.replace(GTM_SITE_KEY, sitekey);
};

const useScript = (script) => {
    useEffect(() => {
        const element = document.createElement('script');
        element.innerHTML = script;
        document.head.append(element);

        return () => {
            document.head.removeChild(element);
        };
    }, [script]);
};

const useNoScriptWithSrc = (src) => {
    useEffect(() => {
        const noScriptEle = document.createElement('noscript');
        const iframe = document.createElement('iframe');
        iframe.src = src;
        iframe.height = 0;
        iframe.width = 0;
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';
        noScriptEle.appendChild(iframe);
        const body = document.getElementsByTagName('body')[0];
        body.insertBefore(noScriptEle, body.firstChild);

        return () => {
            document.body.removeChild(noScriptEle);
        };
    }, [src]);
};

let gtmEventHandler;
const GoogleGtmManager = ({ sitekey, onInitialized }) => {
    const Script = replaceText(GTM_SCRIPT, sitekey);
    const noScriptSrc = replaceText(GTM_NO_SCRIPT_SRC, sitekey);

    useScript(Script);
    useNoScriptWithSrc(noScriptSrc);

    useEffect(() => {
        if (!gtmEventHandler) {
            gtmEventHandler = eventHandler;
        }

        onInitialized(gtmEventHandler);
    }, [onInitialized]);
};

GoogleGtmManager.propTypes = {
    sitekey: PropTypes.string.isRequired,
};

export default GoogleGtmManager;
