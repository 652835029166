import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '@Green-Dot-Corporation/eureka-lib-router-utils';
import Cookies from 'js-cookie';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FeatureLoader from './FeatureLoader';
import ErrorBoundary from '../errors/ErrorBoundary';
import DefaultLayout from '../layout/AppLayout';
import PageNotFound from '../../pages/PageNotFound';
import SDKLanding from '../../pages/SDKLanding';
import { isAuthenticated } from '../../services/appService';
import { buildMediaURL } from '../../utils/urlUtils';
import { DO_NOT_STORE_COOKIE_URLS } from '../../const/appConst';
import RouteChangeListener from '../layout/RouteChangeListener';

const DESIRED_URL_COOKIE_NAME = 'desired_url';
// TODO: Confirm if this validates a route is within enabled routes
const isWithinRoutes = (absoluteUrl, features = []) => {
    const [feature] = features.filter(
        ({ route }) => absoluteUrl.indexOf(route) !== -1,
    );

    return !!feature;
};

class AppLoader extends PureComponent {
    static getDerivedStateFromProps(props, state) {
        const { initialURL, currentPathname } = state;
        const { location = {} } = props;
        const { pathname } = location;

        if (initialURL && pathname !== currentPathname) {
            return { initialURL: '' };
        }

        if (initialURL && pathname === initialURL) {
            return { initialURL: '' };
        }

        // react shows warning if we return undefined
        return null; // eslint-disable-line no-null/no-null
    }

    static defaultProps = {
        appConfig: {},
    };

    static propTypes = {
        appConfig: PropTypes.object,
        location: PropTypes.object,
        layoutModule: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const { location = {} } = props;
        const { pathname } = location;
        const enabledFeatures = this.getEnabledFeatures();
        const hiddenFeatures = this.getFilteredFeatures(enabledFeatures, true);
        const routedFeatures = this.getFilteredFeatures(enabledFeatures);
        const initialURL = this.handleDesiredURL(routedFeatures);

        this.state = {
            hiddenFeatures,
            routedFeatures,
            currentPathname: location.pathname,
        };

        if (initialURL && pathname === '/') {
            this.state.initialURL = initialURL;
        }
    }

    render() {
        const { appConfig, layoutModule: CustomLayout } = this.props;
        const { initialURL } = this.state;

        const AppLayout = CustomLayout || DefaultLayout;

        return (
            <AppLayout appConfig={appConfig}>
                <ErrorBoundary>
                    <RouteChangeListener />
                    {!initialURL && this.renderFeatures()}
                    {initialURL && <Navigate to={initialURL} replace />}
                </ErrorBoundary>
            </AppLayout>
        );
    }

    renderFeatures() {
        const { appConfig } = this.props;
        const { hiddenFeatures, routedFeatures } = this.state;

        return hiddenFeatures
            .map((feature) => (
                <FeatureLoader
                    key={feature.id || Date.now()}
                    feature={feature}
                    appConfig={appConfig}
                    isHidden
                />
            ))
            .concat(
                <Routes key="bluesky-routes">
                    {routedFeatures.map((feature) => {
                        let { route } = feature;

                        if (route && route.endsWith('/')) {
                            route = route.slice(0, -1);
                        }

                        return (
                            <Route
                                key={route}
                                path={`${route}/*`}
                                element={this.renderFeatureLoader(feature)}
                            />
                        );
                    })}
                    <Route path="*" element={<PageNotFound />} />
                    <Route path="sdk-landing" element={<SDKLanding />} />
                </Routes>,
            );
    }

    renderFeatureLoader = (feature) => {
        const { appConfig } = this.props;

        return (
            <>
                <Helmet>
                    <title>{feature.title}</title>
                    <link
                        rel="icon"
                        href={buildMediaURL(appConfig, '/favicon.ico')}
                    />
                </Helmet>
                <FeatureLoader feature={feature} appConfig={appConfig} />
            </>
        );
    };

    handleDesiredURL = (routedFeatures) => {
        const isAuth = isAuthenticated();

        const destinationFeature = this.getDestinationFeature(routedFeatures);
        let { route: destination = '' } = destinationFeature;

        if (isAuth) {
            // navigate user to this url after authenticated
            const url = Cookies.get(DESIRED_URL_COOKIE_NAME);
            Cookies.remove(DESIRED_URL_COOKIE_NAME);

            if (url) {
                destination = url;
            }
        } else {
            // if current url requires auth, then save it in cookies to be redirected after auth
            const currentUrl = window.location.href;
            const withinRoutes = isWithinRoutes(currentUrl, routedFeatures);
            const urlObject = new URL(currentUrl);
            const pathName = urlObject.pathname;

            /* istanbul ignore else  */
            if (!withinRoutes && !DO_NOT_STORE_COOKIE_URLS[pathName]) {
                Cookies.set(DESIRED_URL_COOKIE_NAME, currentUrl);
            }

            const queryParam = window.location.search;
            destination = `${destination}${queryParam}`;
        }

        return destination;
    };

    getEnabledFeatures = () => {
        const {
            appConfig: { featureConfig = {} },
        } = this.props;

        return Object.keys(featureConfig)
            .sort((a, b) => a - b)
            .map((id) =>
                featureConfig[id].moduleSrc
                    ? { ...featureConfig[id], id }
                    : undefined,
            )
            .filter((f) => {
                return !!f && f.id !== 'layout';
            });
    };

    /* istanbul ignore next */
    getFilteredFeatures = (features = [], isHiddenFeature = false) => {
        if (isHiddenFeature) {
            // Hidden features have no routes
            return features.filter((feature) => !feature.route);
        }

        return features.filter((feature) => !!feature.route);
    };

    /* istanbul ignore next */
    getDestinationFeature = (features = []) => {
        const { appConfig } = this.props;
        const { defaultFeature } = appConfig;

        return (
            features.filter(({ id }) => defaultFeature === id)[0] ||
            features[0] ||
            {}
        );
    };
}

export default withRouter(AppLoader);
