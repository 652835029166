const augmentPageInfo = (page, options) => {
    const { pageInfo } = page;
    const { pageName, channel, errorCode } = options;
    const currentPageInfo = { pageName, channel, errorCode };

    if (!pageName) {
        delete currentPageInfo.pageName;
    }

    if (!channel) {
        delete currentPageInfo.channel;
    }

    // eslint-disable-next-line no-param-reassign
    page.pageInfo = {
        ...pageInfo,
        pageName: document.title,
        ...currentPageInfo,
    };
};

const augmentUserInfo = (user, options) => {
    const [info] = user;
    const { profile } = info;
    const [userProfile] = profile;
    const { profileInfo } = userProfile;

    const { accountIdentifier } = options;
    const currentProfile = { account_id: accountIdentifier };

    if (!accountIdentifier) {
        delete currentProfile.account_id;
    }

    userProfile.profileInfo = {
        ...profileInfo,
        ...currentProfile,
    };
};

const augmentImpactInfo = (impact, options) => {
    const { impactinfo } = impact;

    // eslint-disable-next-line no-param-reassign
    impact.impactinfo = {
        ...impactinfo,
        ...options,
    };
};

const augmentEventOptions = (data, options) => {
    const { eventOptions } = options;
    const { eventName, ...rest } = eventOptions ?? { eventName: '' };

    if (!eventName) return;
    // eslint-disable-next-line no-param-reassign
    data[eventName] = {
        ...rest,
    };
};

const preloadedEvents = [];

export const eventHandler = (eventName, options) => {
    try {
        const scope = window;
        const { _satellite: adobe } = scope;

        if (adobe) {
            const data = options ?? {};
            augmentPageInfo(scope.digitalData.page, data);
            augmentUserInfo(scope.digitalData.user, data);
            augmentImpactInfo(scope.digitalData.impact, data);
            augmentEventOptions(scope.digitalData, data);

            while (preloadedEvents.length) {
                adobe.track(preloadedEvents[0]);

                preloadedEvents.shift();
            }

            adobe.track(eventName);
        } else {
            preloadedEvents.push(eventName);
        }
    } catch (error) {
        console.debug('Adobe event handler failed', error);
    }

    return eventName;
};

export const initialize = (appConfig) => {
    window.digitalData = {
        page: {
            pageInfo: {
                brand: String(appConfig.programCode).toLowerCase(),
                site: 'secure',
                channel: 'enroll',
                programCode: String(appConfig.programCode).toLowerCase(),
                productCode: String(appConfig.productCode).toLowerCase(),
                siteRendered: 'responsive',
                modalName: '',
            },
        },
        user: [
            {
                profile: [
                    {
                        profileInfo: {
                            custId: '',
                            status: 'logged_out',
                            personID: '',
                            AccountReferenceID: '',
                            email: '',
                            account_id: '',
                            reg_status: '',
                            cardtype: '',
                        },
                    },
                ],
            },
        ],
        impact: {
            impactinfo: {
                customerID: '',
                orderId: '',
            },
        },
    };

    return eventHandler;
};

export const store = (options) => {
    const scope = window;
    const data = options ?? {};
    augmentPageInfo(scope.digitalData.page, data);
    augmentUserInfo(scope.digitalData.user, data);
    augmentImpactInfo(scope.digitalData.impact, data);
    augmentEventOptions(scope.digitalData, data);
};
export default {};
