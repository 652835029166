import { PureComponent } from 'react';
import { LoadingFlow } from '@Green-Dot-Corporation/eureka-ui-loading';

import { BrowserRouter } from 'react-router-dom';
import AppLoader from './components/loaders/AppLoader';
import AnalyticEngine from './components/analytics/AnalyticEngine';
import ForterFraudDetection from './components/analytics/ForterFraudDetection';

import { setupDatadog } from './services/datadogService';
import { setupBranding } from './services/brandingService';
import { getAppConfig, loadConfigFromBootstrap } from './services/appService';
import Logger from './services/logger/Logger';

import { getFeatureModule } from './services/featureLoaderService';
// Styles
import './styles/blue-sky-app.scss';

export default class BlueSkyApp extends PureComponent {
    state = {
        isLoading: true,
        hasError: false,
    };

    render() {
        const { isLoading, appConfig, hasError, layoutModule } = this.state;

        return (
            <LoadingFlow
                isLoading={isLoading}
                hasError={hasError}
                errorStateLocale={{ title: 'Error' }}
                loadingStateLocale={{ title: 'Loading' }}
            >
                <AnalyticEngine appConfig={appConfig} />
                <BrowserRouter>
                    <AppLoader
                        appConfig={appConfig}
                        layoutModule={layoutModule}
                    />
                </BrowserRouter>
                <ForterFraudDetection appConfig={appConfig} />
            </LoadingFlow>
        );
    }

    componentDidMount() {
        this.initializeApp();
    }

    initializeApp = async () => {
        const newState = {
            isLoading: false,
        };

        try {
            let appConfig = await getAppConfig();

            appConfig = await loadConfigFromBootstrap(appConfig);
            appConfig = this.setupDarkMode(appConfig);

            const promises = Promise.all([
                getFeatureModule(appConfig?.featureConfig?.layout),
                setupBranding(appConfig),
            ]);

            const [layoutModule] = await promises;
            setupDatadog(appConfig);
            newState.appConfig = appConfig;
            newState.layoutModule = layoutModule;
            this.registerGlobalLogger(appConfig);
        } catch (e) {
            console.log(e, e.errorDetails);
            newState.hasError = true;
        } finally {
            this.setState(newState);
        }
    };

    registerGlobalLogger = (appConfig) => {
        // Create a global blueSkyLogger in the application
        // How to use: window.blueSkyLogger.error('message')
        const logger = new Logger(appConfig);
        window.blueSkyLogger = logger;
    };

    setupDarkMode = (appConfig) => {
        const isInDarkMode = !!(
            appConfig.branding.darkModeEnabled &&
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        );

        return {
            ...appConfig,
            branding: { ...appConfig.branding, isInDarkMode },
        };
    };
}
