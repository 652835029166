import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { blueSkyEventHandler } from '../analytics/services/AnalyticsService';

const RouteChangeListener = () => {
    const location = useLocation();

    useEffect(() => {
        blueSkyEventHandler('page view');
    }, [location]);

    return <div style={{ display: 'none' }} />;
};

export default RouteChangeListener;
