import { post } from '@Green-Dot-Corporation/eureka-lib-fetch-utils';
import { v4 as uuidv4 } from 'uuid';
import { REQUEST_ID_HEADER } from '../../const/appConst';

function formatRequest(request) {
    const { message, level, ...reset } = request;
    const { applicationId, productCode, programCode, defaultFeature } = reset;
    const errorCode = `Feature: [${defaultFeature}] ApplicationId: [${applicationId}] ProductCode: [${productCode}] ProgramCode: [${programCode}]`;

    return {
        errorseverity: level,
        errororiginatorid: window.location.href,
        errorbody: message,
        errorcode: errorCode,
    };
}

export const sendLogToGateway = async (request) => {
    const url = `${window.location.origin}/api/v1/app/log`;

    try {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                [REQUEST_ID_HEADER]: uuidv4(),
            },
            body: formatRequest(request),
        };
        await post(url, options);
    } catch (error) {
        // We should ignore this exception
    }
};

export default {};
