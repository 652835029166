import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import AnalyticObserver from './AnalyticObserver';
import AdobeLaunch from './AdobeLaunch';
import GoogleTagManager from './GoogleTagManager';
import GoogleGtmManager from './GoogleGtmManager';
import {
    cleanEventHandlers,
    handleEvent,
    handleRegisterHandler,
    registerGlobalAnalyticsIntance,
} from './services/AnalyticsService';

export default class AnalyticEngine extends PureComponent {
    static propTypes = {
        appConfig: PropTypes.object,
    };

    render() {
        const { appConfig = {} } = this.props;
        const { featureConfig = {} } = appConfig;
        const {
            adobeLaunch: { url: alUrl = '', ...alRest } = {},
            googleTagManager: { url: gtmUrl = '', ...gtmRest } = {},
            googleGtm: { siteKey = '', ...googleGtmRest } = {},
        } = featureConfig;

        return (
            <>
                <AnalyticObserver onEvent={handleEvent} />
                {alUrl && (
                    <AdobeLaunch
                        url={alUrl}
                        {...alRest}
                        appConfig={appConfig}
                        onInitialized={handleRegisterHandler}
                    />
                )}
                {gtmUrl && <GoogleTagManager url={gtmUrl} {...gtmRest} />}
                {siteKey && (
                    <GoogleGtmManager
                        sitekey={siteKey}
                        {...googleGtmRest}
                        onInitialized={handleRegisterHandler}
                    />
                )}
            </>
        );
    }

    componentDidMount() {
        registerGlobalAnalyticsIntance();
    }

    componentWillUnmount() {
        cleanEventHandlers();
    }
}
