const removeSlash = (path, startingPath) => {
    let modifiedPath = path;

    if (path) {
        if (modifiedPath.endsWith('/')) {
            modifiedPath = modifiedPath.slice(0, -1);
        }

        if (!startingPath && modifiedPath.startsWith('/')) {
            modifiedPath = modifiedPath.slice(1);
        }
    }

    return modifiedPath;
};
const buildURL = (appConfig, subpathProp, resourcePath) => {
    const { resourcePath: resourcePathPrefix, branding } = appConfig ?? {};
    const { [subpathProp]: subpath } = branding ?? {};

    let path = resourcePath;

    if (resourcePathPrefix && subpath) {
        path = `${removeSlash(resourcePathPrefix, true)}/${removeSlash(
            subpath,
        )}/${removeSlash(resourcePath)}`;
    }

    return path;
};

export const buildTokenURL = (appConfig, resourcePath) => {
    return buildURL(appConfig, 'tokenPath', resourcePath);
};

export const buildMediaURL = (appConfig, resourcePath) => {
    return buildURL(appConfig, 'mediaPath', resourcePath);
};
