import React from 'react';
import initMirageServer from '@Green-Dot-Corporation/eureka-lib-mock-utils/src/initMirageServer';
import ReactDOM from 'react-dom/client';
import BlueSkyApp from './BlueSkyApp';
import reportWebVitals from './reportWebVitals';
import registerRoutes from './mock/registerRoutes';
import { setupCachedBranding } from './services/brandingService';

// TODO: Do we need this here and in BlueSkyApp.js?
import './styles/blue-sky-app.scss';

if (process.env.NODE_ENV === 'development') {
    // Shutdown any existing instance of server to avoid multiple pretender
    // instances error
    if (window.server && typeof window.server.shutdown === 'function') {
        window.server.shutdown();
    }

    // Create server and pass routes and other options
    window.server = initMirageServer({
        routes() {
            registerRoutes(this);
        },
    });
}

// Wait for the entire document to be fully loaded and parsed before initializing the React application.
// This ensures that the target element 'root' is present in the DOM
function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();

        return;
    }
    document.addEventListener('DOMContentLoaded', fn);
}

ready(() => {
    const root = ReactDOM.createRoot(document.getElementById('root'));

    const renderApp = () => {
        root.render(<BlueSkyApp />);
    };

    setupCachedBranding().then(renderApp).catch(renderApp);

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
});
