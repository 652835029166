import { useEffect } from 'react';
import PropTypes from 'prop-types';

export const VIEW_EVENT = 'data-ae-view';
export const ACTION_EVENT = 'data-ae-action';
export const OPTION_EVENT = 'data-ae-options';
export const SHOULD_SEND_VIEW_EVENT = 'data-ae-shouldSendEvent';

const viewHandlers = [];
const actionHandlers = [];
const handleEvent = (eventType, eventValue, target, option) => {
    switch (eventType) {
        case VIEW_EVENT:
            viewHandlers.forEach((handler) => {
                const { eventName } = option;
                handler(eventName ?? eventValue, {
                    eventType,
                    pageName: eventValue,
                    eventOptions: option,
                    target,
                });
            });
            break;
        case ACTION_EVENT:
            actionHandlers.forEach((handler) =>
                handler(eventValue, { eventType, target }),
            );
            break;
        default:
            break;
    }
};

const handleTrackingEvent = (target) => {
    const eventName = target.getAttribute(VIEW_EVENT);
    const eventData = target.getAttribute(OPTION_EVENT);
    const shouldSendEvent = !(
        target.getAttribute(SHOULD_SEND_VIEW_EVENT) === 'false'
    );

    if (!shouldSendEvent) return;

    let data = {};

    if (eventData) {
        try {
            data = JSON.parse(eventData);
        } catch (e) {
            console.error('error parsing event data', e);
        }
    }

    if (eventName) {
        handleEvent(VIEW_EVENT, eventName, target, data);
    }
};

let observer;
export const registerViewEventListener = (eventHandler, root = document) => {
    if (!observer) {
        observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const { addedNodes } = mutation;
                const [target] = addedNodes;

                if (target && target.querySelectorAll) {
                    const elements = target.querySelectorAll(`[${VIEW_EVENT}]`);

                    elements.forEach((el) => {
                        handleTrackingEvent(el);
                    });

                    if (target.getAttribute) {
                        handleTrackingEvent(target);
                    }
                }
            });
        });

        observer.observe(root, { childList: true, subtree: true });
    }

    if (!viewHandlers.includes(eventHandler)) {
        viewHandlers.push(eventHandler);
    }

    return observer;
};

let isListenerInitialized;
export const registerActionEventListener = (eventHandler, root = document) => {
    if (!isListenerInitialized) {
        isListenerInitialized = true;

        root.addEventListener('pointerdown', (ev) => {
            const { target } = ev;
            const eventValue = target.getAttribute(ACTION_EVENT);

            if (eventValue) {
                handleEvent(ACTION_EVENT, eventValue, target);
            }
        });
    }

    if (!actionHandlers.includes(eventHandler)) {
        actionHandlers.push(eventHandler);
    }

    return isListenerInitialized;
};

const AnalyticObserver = (props) => {
    const { onEvent } = props;

    useEffect(() => {
        registerActionEventListener(onEvent);
        registerViewEventListener(onEvent);

        return () => {
            actionHandlers.splice(actionHandlers.indexOf(onEvent), 1);
            viewHandlers.splice(viewHandlers.indexOf(onEvent), 1);
        };
    }, [onEvent]);

    return (
        <div style={{ display: 'none' }} data-analytic-observer={Date.now()} />
    );
};

AnalyticObserver.propTypes = {
    onEvent: PropTypes.func.isRequired,
};

export default AnalyticObserver;
