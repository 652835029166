import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const getBaseInfo = (appConfig) => {
    const { programCode, featureConfig } = appConfig;
    const { datadog } = featureConfig ?? {};
    const { applicationId, clientToken, environment } = datadog ?? {};

    if (!(clientToken && applicationId && environment)) {
        return undefined;
    }

    return {
        clientToken,
        site: 'datadoghq.com',
        service: programCode,
        env: environment,
        version: '1.0.0',
        applicationId,
    };
};

export const setupDatadog = (appConfig) => {
    const baseInfo = getBaseInfo(appConfig);

    if (!baseInfo) return;

    datadogLogs.init({
        ...baseInfo,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
    });

    datadogRum.init({
        ...baseInfo,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
};

export default {};
