import {
    IS_APP_CONFIG_AUTHENTICATED_KEY,
    BRANDING_CACHE_KEY,
} from '../const/appConst';

export const cacheBranding = (config) => {
    window.localStorage.setItem(BRANDING_CACHE_KEY, JSON.stringify(config));
};

export const getCachedBranding = () => {
    return window.localStorage.getItem(BRANDING_CACHE_KEY);
};

export const shouldGetAuthenticatedAppConfig = () => {
    let stageKeyValue = window.sessionStorage.getItem(
        IS_APP_CONFIG_AUTHENTICATED_KEY,
    );

    if (!stageKeyValue) {
        stageKeyValue = window.localStorage.getItem(
            IS_APP_CONFIG_AUTHENTICATED_KEY,
        );
    }

    return stageKeyValue === 'true';
};
