const loadComponent = (scope, module) => {
    return async () => {
        // eslint-disable-next-line no-undef
        await __webpack_init_sharing__('default');
        const container = window[scope]; // or get the container somewhere else
        // eslint-disable-next-line camelcase,no-undef
        await container.init(__webpack_share_scopes__.default);
        const factory = await window[scope].get(module);
        const Module = factory();

        return Module;
    };
};

const loadModuleScript = (modulePath) => {
    return new Promise((resolve, reject) => {
        const element = document.createElement('script');
        const url = modulePath;
        element.src = url;
        element.type = 'text/javascript';
        element.async = true;

        element.onload = () => {
            resolve();
        };

        element.onerror = (e) => {
            reject(e);
        };

        document.head.appendChild(element);
    });
};

export const getFeatureLoader = async (featureDef) => {
    const {
        moduleSrc,
        moduleName: featureModuleName,
        subModuleName: featureSubModuleName,
    } = featureDef;
    await loadModuleScript(moduleSrc);

    let moduleName = featureModuleName;

    if (!moduleName) {
        if (moduleSrc.indexOf('Entry.js') !== -1) {
            // default it to src name if not present as prop
            moduleName = moduleSrc.split('Entry.js')[0].split('/').slice(-1);
        } else {
            moduleName = moduleSrc.split('.js')[0].split('/').slice(-1);
        }
    }

    const subModuleName = featureSubModuleName ?? moduleName;

    return loadComponent(moduleName, `./${subModuleName}`);
};

export const getFeatureModule = async (featureDef) => {
    if (!featureDef) {
        return undefined;
    }
    const featureLoader = await getFeatureLoader(featureDef);

    const feature = await featureLoader();

    return feature.default;
};

export default {};
