import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useScript } from '@Green-Dot-Corporation/web-app-utils-hooks';
import { initialize } from './services/AdobeAnalyticsService';

let adobeLaunchEventHandler;
const AdobeLaunch = ({ appConfig, url, onInitialized }) => {
    useScript(url);

    useEffect(() => {
        if (!adobeLaunchEventHandler) {
            adobeLaunchEventHandler = initialize(appConfig, window);
        }

        onInitialized(adobeLaunchEventHandler);
    }, [appConfig, onInitialized]);

    return <div style={{ display: 'none' }} data-adobe-launch={url} />;
};

AdobeLaunch.propTypes = {
    url: PropTypes.string.isRequired,
    appConfig: PropTypes.object.isRequired,
    onInitialized: PropTypes.func.isRequired,
};

export default AdobeLaunch;
