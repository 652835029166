import { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import deviceInfo from '@Green-Dot-Corporation/eureka-lib-device-info-utils';
import Header from './Header';

// Styles
import './styles/app-layout.scss';

const { isWebview } = deviceInfo;
class AppLayout extends Component {
    static defaultProps = {
        appConfig: {},
    };

    static propTypes = {
        contextCls: PropTypes.string,
        appConfig: PropTypes.object,
    };

    render() {
        const { contextCls, appConfig } = this.props;
        const shouldHideHeader = this.shouldHideHeader();
        const baseClass =
            this.baseCls +
            (appConfig?.branding?.isInDarkMode ? '__dark-mode' : '');

        return (
            <div className={cx(this.baseCls, contextCls, baseClass)}>
                {!shouldHideHeader && this.renderHeader()}
                {this.renderMain()}
            </div>
        );
    }

    renderHeader = () => {
        const { appConfig } = this.props;

        return (
            <div className={`${this.baseCls}__header-container`}>
                <Header appConfig={appConfig} />
            </div>
        );
    };

    renderMain = () => {
        const { children } = this.props;

        return (
            <main className={`${this.baseCls}__main-container`}>
                {children}
            </main>
        );
    };

    shouldHideHeader = () => {
        return isWebview || window.location.search.includes('isMobile=true');
    };

    baseCls = 'blue-sky-app-layout';
}

export default AppLayout;
