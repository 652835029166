import { eventHandler as gtmEventHandler } from './GoogleGtmAnalyticsService';
import {
    eventHandler as adobeEventHandler,
    store as adobeStore,
} from './AdobeAnalyticsService';

let handlers = [];

window.blueSkyAnalytics = () => {};

export const handleRegisterHandler = (eventHandler) => {
    if (!handlers.includes(eventHandler)) {
        handlers.push(eventHandler);
    }
};

export const handleEvent = (eventName, options) => {
    try {
        handlers.forEach((handler) => handler(eventName, options));
    } catch (error) {
        console.debug('blueSkyEventHandler faild', error);
    }
};

export const cleanEventHandlers = () => {
    handlers = [];
    window.blueSkyAnalytics = undefined;
};

export const blueSkyEventHandler = (eventName, options) => {
    try {
        gtmEventHandler(eventName, options ?? {});
        // Adobe parameters require special processing
        const adoceOptions = {
            ...options,
            eventOptions: {
                ...options,
                eventName,
            },
        };
        adobeEventHandler(eventName, adoceOptions ?? {});
    } catch (error) {
        console.debug('blueSkyEventHandler faild', error);
    }
};

export const registerGlobalAnalyticsIntance = () => {
    window.blueSkyAnalytics = blueSkyEventHandler;
};

export const handleStoreEvent = (options) => {
    adobeStore(options);
};

export default {};
